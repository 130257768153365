import React from 'react';
import { TextField, SimpleShowLayout, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import BadgeField from '../common/fields/BadgeField';
import StatusField from './StatusField';

const PackageDrawer = props => (
	<SimpleShowLayout {...props}>
		<TextField source="reference" />
		<StatusField />
		<TextField source="name" />
		<TextField source="description" options={{ multiline: true }} rows="4" />
		<TextField source="shipping_address" />
	</SimpleShowLayout>
);

const PackageRow = props => (
	<Datagrid drawer={<PackageDrawer {...props} />} {...props}>
		<LinkFieldButton label="Package Reference" sortBy="reference">
			<TextField source="reference" />
		</LinkFieldButton>
		<LinkFieldButton label="Site" source="site" basePath="/sites">
			<TextField source="name" />
		</LinkFieldButton>
		<LinkFieldButton label="Customer Reference" sortBy="customer_reference">
			<TextField source="customer_reference" />
		</LinkFieldButton>
		<StatusField />
		<BadgeField source="services_count" label="Services" sortable={false} />
		<ShowButton />
	</Datagrid>
);

export default PackageRow;
import React from 'react';
import { TextField, SimpleShowLayout, ReferenceField, ShowButton } from 'react-admin';

import Datagrid from '../common/Datagrid';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';


const StarlinkTerminalDrawer = (props) => (
    <SimpleShowLayout {...props}>
        <TextField source="terminal_id" label="Terminal ID" />
        <TextField source="kit_serial_number" />
        <TextField source="dish_serial_number" />
        <MapComponent height="300px" />
    </SimpleShowLayout>
);

const StarlinkTerminalRow = ({ children, ...props }) => (
    <Datagrid drawer={<StarlinkTerminalDrawer {...props} />} {...props}>
        <ReferenceField label="Site" source="site.id" sortBy="site.name" reference="sites" allowEmpty link="show" sortable={false}>
            <TextField source="name" />
        </ReferenceField>
        <LinkFieldButton label="Terminal ID" sortBy="terminal_id">
            <TextField source="terminal_id" />
        </LinkFieldButton>
        <TextField label="KIT Serial" source="kit_serial_number" />
        {children}
        <ShowButton />
    </Datagrid>
);

StarlinkTerminalRow.defaultProps = {
    basePath: '/starlink_terminals'
};

export default StarlinkTerminalRow;
import React from 'react';
import { TextField, SimpleShowLayout, ReferenceArrayField, Link, NumberField, ShowButton, useTranslate } from 'react-admin';
import BuildIcon from '@material-ui/icons/Build';

import Datagrid from '../common/Datagrid';
import DateField from '../common/DateField';
import LinkFieldButton from '../common/LinkFieldButton';
import MapComponent from '../common/MapComponent';
import DeviceListField from '../devices/DeviceListField';
import IconButton from '../common/IconButton';

const DevicesField = (props) => {
	if (props.record?.devices?.length <= 4) {
		return (
			<ReferenceArrayField label="Devices" reference="devices" source="devices" sortable={false} {...props}>
				<DeviceListField style={{ display: 'inline-block' }} />
			</ReferenceArrayField>
		);
	}

	return (
		<DeviceListField style={{ display: 'inline-block' }} data={props.record.devices} />
	);
};

const SiteDrawerActions = ({ record }) => {
	const translate = useTranslate();

	return (
		<IconButton icon={<BuildIcon />} variant="outlined" component={Link} to={`/tickets/new/${record ? record.id : ''}`}>{translate('Create ticket')}</IconButton>
	);
};

const SiteDrawer = (props) => (
	<SimpleShowLayout {...props}>
		<TextField source="id" />
		<TextField source="name" />
		<TextField source="description" options={{ multiline: true }} />
		<TextField source="address" />
		<MapComponent height="300px" />
	</SimpleShowLayout>
);

const SiteRow = ({ children, ...props }) => (
	<Datagrid drawer={<SiteDrawer {...props} />} drawerActions={<SiteDrawerActions />} {...props}>
		<LinkFieldButton label="Name" sortBy="name">
			<TextField source="name" />
		</LinkFieldButton>
		<TextField source="address" />
		<DevicesField label="Devices" />
		<NumberField label="Mobile Current Month Usage(GB)" source="current_month_usage" />
		<NumberField label="StarLink Current Month Usage(GB)" source="starlink_current_month_usage" />
		<DateField source="updated_at" />
		{children}
		<ShowButton />
	</Datagrid>
);

export default SiteRow;
import ptBrMessages from 'ra-language-pt-br';

const ptBr = {
    ...ptBrMessages,
    resources: {
        sites: {
            // name: ' |||| ',
            fields: {
                name: 'Nome',
                address: 'Endereço',
                updated_at: 'Atualizado em',
            }
        },
        simpools: {
            name: 'Grupo de SIM |||| Grupos de SIM',
            fields: {
                name: 'Nome',
                invoicing: 'Faturamento',
                updated_at: 'Atualizado em',
                created_at: 'Criado em',
                description: 'Descrição',
                cards_count: 'Número de Cartões',
            }
        },
        simcards: {
            name: 'SIM |||| SIMs',
            fields: {
                iccid: 'ICCID',
                provider_name: 'Nome do Provedor',
                active: 'Ativo'
            }
        },
        devices: {
            name: 'Dispositivo |||| Dispositivos',
            fields: {
                serial: 'Número de Série',
                site: 'Site',
                last_online: 'Último Online',
                wans: 'WANs',
                name: 'Nome',
                ip_address: 'Endereço IP',
                updated_at: 'Atualizado em',
                online_status: 'Status Online',
                address: 'Endereço',
                data_limit_contacts: 'Limite de Dados Contatos',
                created_at: 'Criado em'
            }
        },
        lines: {
            name: 'Linha |||| Linhas',
            fields: {
                subscription_id: 'ID da Assinatura',
                provider_name: 'Nome do Provedor',
                status: 'Status',
                line_type: 'Tipo de Linha'
            }
        },
        orders: {
            name: 'Pedido |||| Pedidos',
            fields: {
                reference: 'Referência',
                po_reference: 'Referência do Pedido',
                customer_name: 'Nome do Cliente',
                data_region: 'Região de Dados',
                number: 'Número',
            }
        },
        invoices: {
            name: 'Fatura |||| Faturas',
            fields: {
                invoice_type: 'Tipo de Fatura',
                invoiced_at: 'Faturado em',
                total_untaxed: 'Total Sem Imposto',
                start_date: 'Data de Início',
                end_date: 'Data de Término',
            }
        },
        'usages/report': {
            name: 'Relatório de Uso |||| Relatórios de Uso',
            fields: {
                site: 'Site',
                pool: 'Grupo',
                device: 'Dispositivo',
                iccid: 'ICCID',
                start_date: 'Data de Início',
                end_date: 'Data de Término',
                group_by: 'Agrupar Por',
            },
        },
        data_pricings: {
            name: 'Precificação de Dados |||| Precificações de Dados',
            fields: {
                provider: 'Provedor',
                country_code: 'Código do País',
                imsi: 'IMSI',
            },
        },
        location_updates: {
            // name: ' |||| ',
            fields: {
                timestamp: 'Data e Hora',
                iccid: 'ICCID',
                type: 'Tipo',
                mcc: 'MCC',
                mnc: 'MNC',
                network_name: 'Nome da Rede',
                imsi: 'IMSI',
                imsi_id: 'ID do IMSI'
            },
        },
        starlink_terminals: {
            name: 'Terminal Starlink |||| Terminais Starlink',
            fields: {
                terminal_id: 'ID do Terminal',
                kit_serial_number: 'Número de Série do KIT',
                dish_serial_number: 'Número de Série da Antena',
                alert_install_pending: 'Alerta de Instalação Pendente',
                alert_is_heating: 'Alerta de Aquecimento',
                alert_mast_not_near_vertical: 'Alerta de Mastro Não Vertical',
                alert_motors_stuck: 'Alerta de Motores Travados',
                alert_roaming: 'Alerta de Roaming',
                alert_slow_ethernet_speeds: 'Alerta de Velocidade de Ethernet Lenta',
                alert_thermal_shutdown: 'Alerta de Desligamento Térmico',
                alert_unexpected_location: 'Alerta de Localização Inesperada'
            }
        },
        'starlink_usages/report': {
            name: 'Relatório de Uso do Starlink |||| Relatórios de Uso do Starlink',
            fields: {
                group_by: 'Agrupar Por',
                start_date: 'Data de Início',
                end_date: 'Data de Término',
            }
        },
        packages: {
            name: 'Pacote |||| Pacotes',
            fields: {
                customer_reference: 'Referência do Cliente',
                invoice_regroupment_reference: 'Referência de Agrupamento de Faturas',
                start_date: 'Data de Início',
                end_date: 'Data de Término'
            }
        },
        users: {
            name: 'Usuário |||| Usuários',
            fields: {
                email: 'E-mail',
                firstname: 'Primeiro Nome',
                lastname: 'Sobrenome',
                password: 'Senha',
                is_manager: 'É Gerente',
                can_order: 'Pode Fazer Pedidos',
                can_manage_users: 'Pode Gerenciar Usuários'
            },
        },
    },
    'Search': 'Buscar',
    'Account': 'Conta',
    'Support': 'Suporte',
    'summary': 'Resumo',
    'Name': 'Nome',
    'Devices': 'Dispositivos',
    'Device': 'Dispositivo',
    'Site': 'Site',
    'Simcard': 'Cartão SIM',
    'ICCID': 'ICCID',
    'Wans': 'WANs',
    'Data Usage monthly (GB)': 'Uso de Dados Mensal (GB)',
    'Data Usage last 30 days (GB)': 'Uso de Dados dos Últimos 30 Dias (GB)',
    'Create ticket': 'Criar Chamado',
    'Move to sim pool': 'Mover para o Grupo de SIM',
    'Are you sure to move the simcard to the SIM pool?': 'Tem certeza de mover o cartão SIM para o grupo de SIM?',
    'Pool': 'Grupo',
    'Current Month Usage (GB)': 'Uso do Mês Atual (GB)',
    'Sync': 'Sincronizar',
    'Device synchronized': 'Dispositivo sincronizado',
    'The device is now rebooting': 'O dispositivo está reiniciando',
    'Reboot failed': 'Falha ao reiniciar',
    'Reboot': 'Reiniciar',
    'Reboot device': 'Reiniciar dispositivo',
    'Do you want to reboot the device': 'Deseja reiniciar o dispositivo?',
    'Status': 'Status',
    'Network type': 'Tipo de Rede',
    'Online at': 'Online em',
    'Product': 'Produto',
    'Product name': 'Nome do Produto',
    'Data Limit Alert (GB)': 'Alerta de Limite de Dados (GB)',
    'Create an order': 'Criar um pedido',
    'Quote Request Only': 'Somente Solicitação de Orçamento',
    'Customer name': 'Nome do Cliente',
    'Data bundle': 'Pacote de Dados',
    'Bundle': 'Pacote',
    'Overconsumption': 'Sobrecarga',
    'Fixed IP': 'IP Fixo',
    'Expected Start Date': 'Data de Início Esperada',
    'Duration in months (min 12)': 'Duração em meses (mínimo 12)',
    'Expected bitrate Down': 'Taxa de Transferência Esperada para Baixo',
    'Expected bitrate Up': 'Taxa de Transferência Esperada para Cima',
    'Site Address': 'Endereço do Site',
    'Street': 'Rua',
    'Number': 'Número',
    'Zip Code': 'CEP',
    'City': 'Cidade',
    'Country': 'País',
    'Latitude': 'Latitude',
    'Longitude': 'Longitude',
    'Remarks (ordinary building, circus, ...)': 'Observações (edifício comum, circo, ...)',
    'Contact Person': 'Pessoa de Contato',
    'Site Contact Firstname': 'Primeiro Nome do Contato do Site',
    'Site Contact Lastname': 'Sobrenome do Contato do Site',
    'Site Contact Email': 'E-mail do Contato do Site',
    'Site Contact Phone': 'Telefone do Contato do Site',
    'Shipping': 'Envio',
    'VAT Number': 'Número de IVA',
    'Invoice email address': 'Endereço de E-mail para Fatura',
    'xDSL Line': 'Linha xDSL',
    'Add a Fixed Line': 'Adicionar uma Linha Fixa',
    'Line Technology': 'Tecnologia da Linha',
    'Order created': 'Pedido criado',
    'Send': 'Enviar',
    'Send order': 'Enviar pedido',
    'Error when sending order': 'Erro ao enviar pedido',
    'Invoicing': 'Faturamento',
    'Order sended': 'Pedido enviado',
    'INTRA GOODS 0%': 'INTRA BENS 0%',
    'INTRA SERVICES 0%': 'INTRA SERVIÇOS 0%',
    'EXPORT GOODS 0%': 'EXPORTAÇÃO DE BENS 0%',
    'EXPORT SERVICES 0%': 'EXPORTAÇÃO DE SERVIÇOS 0%',
    'NA': 'N/A',
    'SEAGOING': 'NAVEGAÇÃO MARÍTIMA',
    'Export data SIM only': 'Exportar dados apenas de SIM',
    'Total': 'Total',
    'Provider': 'Provedor',
    'Network': 'Rede',
    'Price/MB': 'Preço/MB',
    'Usage reports': 'Relatórios de Uso',
    'Usage Reports': 'Relatórios de Uso',
    'Data pricing': 'Precificação de Dados',
    'Map': 'Mapa',
    'Network events': 'Eventos de Rede',
    'Create order': 'Criar pedido',
    'Result code': 'Código de Resultado',
    'Access at': 'Acesso em',
    'Forgot password': 'Esqueceu a senha',
    'An email has been sent': 'Um e-mail foi enviado',
    'Reset password': 'Redefinir senha',
    'Ticket created': 'Chamado criado',
    'Create a ticket': 'Criar um chamado',
    'Forgot password?': 'Esqueceu a senha?',
    'Archived': 'Arquivado',
    'Notes': 'Notas',
    'Manager': 'Gerente',
    'Description': 'Descrição',
    'Welcome to the %{name} portal': 'Bem-vindo ao portal %{name}',
    'This month': 'Este mês',
    'Last month': 'Mês passado',
    'Last 6 months': 'Últimos 6 meses',
    'Last 12 months': 'Últimos 12 meses',
    'ICCID in Pool': 'ICCID no Grupo',
    'ICCID in Device': 'ICCID no Dispositivo',
    'Current Month Data Usage by Site': 'Uso de Dados do Mês Atual por Site',
    'Current Month Data Usage by Simpool': 'Uso de Dados do Mês Atual por Grupo de SIM',
    'SIM Management': 'Gerenciamento de SIM',
    'Suspend SIM': 'Suspender SIM',
    'Enable SIM': 'Ativar SIM',
    'Are you sure you want to enable the SIM %{iccid}?': 'Tem certeza de que deseja ativar o SIM %{iccid}?',
    'Are you sure you want to suspend the SIM %{iccid}?': 'Tem certeza de que deseja suspender o SIM %{iccid}?',
    'SIM suspended': 'SIM suspenso',
    'SIM enabled': 'SIM ativado',
    'Amount due': 'Valor devido',
    'Amount over due': 'Valor em atraso',
    'Daily': 'Diário',
    'Monthly': 'Mensal',
    'Yearly': 'Anual',
    'Mobile': 'Móvel',
    'Usage': 'Uso',
    'Mobile Current Month Usage(GB)': 'Uso Atual do Mês (GB)',
    'StarLink Current Month Usage(GB)': 'Uso Atual do Mês (GB) do StarLink',
    'Serial': 'Número de Série',
    'Simpools': 'Grupos de SIM',
    'Telemetry': 'Telemetria',
    'OOB': 'OOB',
    'Terminal ID': 'ID do Terminal',
    'Antenna status': 'Status da Antena',
    'Downlink speed': 'Velocidade de Download',
    'Uplink speed': 'Velocidade de Upload',
    'Latency': 'Latência',
    'Uptime': 'Tempo de Atividade',
    'State from OOBM': 'Estado do OOBM',
    'Software update state': 'Estado da Atualização de Software',
    'Unobstructed ratio': 'Proporção de Linha de Visão Livre',
    'Antenna obstruction': 'Obstrução da Antena',
    'Network statistics': 'Estatísticas de Rede',
    '15m': '15m',
    '1 hr': '1 hr',
    '24 hrs': '24 hrs',
    '7 days': '7 dias',
    '30 days': '30 dias',
    'Downlink throughput': 'Throughput de Download',
    'Uplink throughput': 'Throughput de Upload',
    'Ping drop rate': 'Taxa de Perda de Ping',
    'Ping drop': 'Perda de Ping',
    'Obstruction': 'Obstrução',
    'Stats export': 'Exportar Estatísticas',
    'Never': 'Nunca',
    'Data Usage': 'Uso de Dados',
    'Settings': 'Configurações',
    'Firstname': 'Primeiro Nome',
    'Lastname': 'Sobrenome',
    'Password': 'Senha',
    'KIT Serial': 'Número de Série do KIT',
    'Package Reference': 'Referência do Pacote',
    'Customer Reference': 'Referência do Cliente',
    'Services': 'Serviços',
    'Line': 'Linha',
    'Custom': 'Personalizado',
    'Package ID Reference': 'Referência do ID do Pacote',
    'Draft': 'Rascunho',
    'Provisioning': 'Provisionamento',
    'Deployed': 'Implantado',
    'Paused': 'Pausado',
    'Ended': 'Encerrado',
    'Shipping Address': 'Endereço de Envio',
    'Shipping Contact': 'Contato de Envio',
    'Sold Hardware': 'Hardware Vendido',
    'DRAFT': 'RASCUNHO',
    'PROVISIONING': 'PROVISIONAMENTO',
    'DELIVERED': 'ENTREGUE',
    'ENDING': 'ENCERRANDO',
    'ENDED': 'ENCERRADO',
    'Running': 'Em Execução',
    'LINE': 'LINHA',
    'Price': 'Preço',
    'Billing address': 'Endereço de Faturamento',
    'Starlink Usage reports': 'Relatórios de Uso do Starlink',
    '%{length} of %{total} devices': '%{length} de %{total} dispositivos',
    'Pending packages': 'Pacotes pendentes',
    'Alerts': 'Alertas'
};

export default ptBr;
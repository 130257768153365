import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import {
	translate,
	DashboardMenuItem as RADashboardMenuItem,
	MenuItemLink as RAMenuItemLink,
	getResources,
	WithPermissions
} from 'react-admin';
import inflection from 'inflection';
import preval from 'preval.macro';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import MapIcon from '@material-ui/icons/Map';
import Box from '@material-ui/core/Box';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

import SubMenu from './common/SubMenu';
import sites from './sites';
import usages from './usages';
import simcards from './simcards';
import simpools from './simpools';
import devices from './devices';
import users from './users';
import data_pricings from './data_pricings';
import invoices from './invoices';
import starlink_terminals from './starlink_terminals';
import packages from './packages';


const styles = theme => ({
	active: {
		backgroundColor: theme.palette.action.hover
	}
});

const MenuItemLink = withStyles(styles)(RAMenuItemLink);
const DashboardMenuItem = withStyles(styles)(RADashboardMenuItem);

const translatedResourceName = (resources, name, translate) => {
	const resource = resources.find(resource => resource.name === name);

	if (!resource) {
		return name;
	}

	return (
		translate(`resources.${resource.name}.name`, {
			smart_count: 2,
			_:
				resource.options && resource.options.label
					? translate(resource.options.label, {
						smart_count: 2,
						_: resource.options.label,
					})
					: inflection.humanize(inflection.pluralize(resource.name))
		})
	);
};

class Menu extends Component {
	state = {
		menuSim: true
	};

	static propTypes = {
		onMenuClick: PropTypes.func,
		logout: PropTypes.object,
	};

	handleToggle = menu => {
		this.setState(state => ({ [menu]: !state[menu] }));
	};

	getBuildDate = () => {
		const buildDate = moment(preval`module.exports = new Date();`);
		const now = moment.now();
		return moment.duration(buildDate - now).humanize(true);
	};

	render() {
		const { onMenuClick, open, translate, resources } = this.props;

		return (
			<React.Fragment>
				<DashboardMenuItem onClick={onMenuClick} />
				<MenuItemLink
					to={`/sites`}
					primaryText={translatedResourceName(resources, 'sites', translate)}
					leftIcon={<sites.icon />}
					onClick={onMenuClick}
				/>
				<SubMenu
					handleToggle={() => this.handleToggle('menuSim')}
					isOpen={this.state.menuSim}
					sidebarIsOpen={open}
					name={translate('SIM Management')}
				>
					<MenuItemLink
						to={`/simcards`}
						primaryText={translatedResourceName(resources, 'simcards', translate)}
						leftIcon={<simcards.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/simpools`}
						primaryText={translatedResourceName(resources, 'simpools', translate)}
						leftIcon={<simpools.icon />}
						onClick={onMenuClick}
					/>
					<MenuItemLink
						to={`/data_pricings`}
						primaryText={translate('Data pricing')}
						leftIcon={<data_pricings.icon />}
						onClick={onMenuClick}
					/>
				</SubMenu>
				<MenuItemLink
					to={`/devices`}
					primaryText={translatedResourceName(resources, 'devices', translate)}
					leftIcon={<devices.icon />}
					onClick={onMenuClick}
				/>
				<WithPermissions
					render={({ permissions }) => (
						permissions && permissions.includes('can_view_starlinks') ?
							<MenuItemLink
								to={`/starlink_terminals`}
								primaryText={translatedResourceName(resources, 'starlink_terminals', translate)}
								leftIcon={<starlink_terminals.icon />}
								onClick={onMenuClick}
							/>
							: null
					)}
				/>
				<WithPermissions
					render={({ permissions }) => (
						permissions && permissions.includes('can_view_packages') ?
							<MenuItemLink
								to={`/packages`}
								primaryText={translatedResourceName(resources, 'packages', translate)}
								leftIcon={<packages.icon />}
								onClick={onMenuClick}
							/>
							: null
					)}
				/>
				<WithPermissions
					render={({ permissions }) => (
						permissions && permissions.includes('can_view_invoices') ?
							<MenuItemLink
								to={`/invoices`}
								primaryText={translatedResourceName(resources, 'invoices', translate)}
								leftIcon={<invoices.icon />}
								onClick={onMenuClick}
							/>
							: null
					)}
				/>
				<SubMenu
					handleToggle={() => this.handleToggle('menuReports')}
					isOpen={this.state.menuReports}
					sidebarIsOpen={open}
					name={translate('Usage Reports')}
				>
					<MenuItemLink
						to={`/usages/reports`}
						primaryText="Mobile"
						leftIcon={<usages.icon />}
						onClick={onMenuClick}
						dense
					/>
					<WithPermissions
						render={({ permissions }) => (
							permissions && permissions.includes('can_view_starlinks') ?
								<MenuItemLink
									to={`/starlink_usages/reports`}
									primaryText="Starlink"
									leftIcon={<usages.icon />}
									onClick={onMenuClick}
									dense
								/>
								: null
						)}
					/>

				</SubMenu>
				<MenuItemLink
					to={`/devices/map`}
					primaryText={translate('Map')}
					leftIcon={<MapIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/location_updates`}
					primaryText={translate('Network events')}
					leftIcon={<SignalCellularAltIcon />}
					onClick={onMenuClick}
				/>
				<WithPermissions
					render={({ permissions }) => (
						permissions && permissions.includes('can_manage_users') ?
							<MenuItemLink
								to={`/users`}
								primaryText={translatedResourceName(resources, 'users', translate)}
								leftIcon={<users.icon />}
								onClick={onMenuClick}
							/>
							: null
					)}
				/>
				<Box className="footer-menu">
					<Typography variant="caption">build {this.getBuildDate()}</Typography>
				</Box>
			</React.Fragment>
		);
	}
}

const mapStateToProps = state => ({
	open: state.admin.ui.sidebarOpen,
	theme: state.theme,
	// locale: state.i18n.locale,
	resources: getResources(state)
});

const enhance = compose(
	withRouter,
	connect(
		mapStateToProps,
		{}
	),
	translate
);

export default enhance(Menu);
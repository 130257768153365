import React, { useState } from 'react';
import {
	TabbedShowLayout,
	SimpleShowLayout,
	Tab,
	TextField,
	// ReferenceArrayField,
	ReferenceManyField,
	Link,
	Pagination,
	useTranslate,
	usePermissions,
	useLocale,
	useRecordContext
} from 'react-admin';
import { Helmet } from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import Show, { ShowActions } from '../common/Show';
import MapComponent from '../common/MapComponent';
import UsageGraph from '../common/UsageGraph';
import IconButton from '../common/IconButton';
import DeviceRow from '../devices/DeviceRow';
import StarlinkTerminalRow from '../starlink_terminals/StarlinkTerminalRow';
// import LineRow from '../lines/LineRow';
import PackageServiceGridList from '../packages/PackageServiceGridList';
import PackageLineGrid from '../package_lines/PackageLineGrid';


const SiteTitle = ({ record }) => {
	const translate = useTranslate();
	const title = translate('resources.sites.name', { smart_count: 1, _: 'Site' });

	return (
		<>
			<Helmet>
				<title>{`KB - ${title}[${record.name}]`}</title>
			</Helmet>
			<span>{record.name} </span>
		</>
	);
};

const SiteActions = (props) => {
	const translate = useTranslate();

	return (
		<ShowActions {...props}>
			<IconButton variant="outlined" icon={<BuildIcon />} component={Link} to={`/tickets/new/${props.data ? props.data.id : ''}`}>{translate('Create ticket')}</IconButton>
		</ShowActions>
	);
};

const Content = (props) => (
	<Grid container>
		<Grid item md={3} sm={12}>
			<SimpleShowLayout {...props}>
				<TextField source="id" />
				<TextField source="name" />
				<TextField source="description" label="Notes" options={{ multiline: true }} />
				<TextField source="address" />
				<TextField source="lat" label="Latitude" />
				<TextField source="lng" label="Longitude" />
			</SimpleShowLayout>
		</Grid>
		<Grid item md={9} sm={12}>
			<Grid container>
				<Grid item md={4} xs={12}>
				</Grid>
				<Grid item md={8} xs={12}>
					<MapComponent {...props} height="300px" />
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

const DeviceList = ({ record }) => {
	const translate = useTranslate();

	if ((!record?.devices || record?.devices?.length === 0) && (!record?.starlink_terminals || !record?.starlink_terminals?.length === 0)) {
		return null;
	}

	return (
		<>
			<Typography variant="h5">{translate('Devices')}</Typography>
			{record?.devices?.length > 0 && (
				<>
					<Typography variant="h6">{translate('Peplink')}</Typography>
					<ReferenceManyField record={record} addLabel={false} reference="devices" target="site_id" perPage={10} pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}>
						<DeviceRow exclude={['site']} />
					</ReferenceManyField>
				</>
			)}

			{record?.starlink_terminals?.length > 0 && (
				<>
					<Typography variant="h6">{translate('Starlink')}</Typography>
					<ReferenceManyField record={record} addLabel={false} reference="starlink_terminals" target="site_id" perPage={10} pagination={<Pagination rowsPerPageOptions={[10, 20, 50]} />}>
						<StarlinkTerminalRow exclude={['site']} />
					</ReferenceManyField>
				</>
			)}
		</>
	);
};

// const LineList = ({ record }) => {
// 	const translate = useTranslate();

// 	if (!record?.lines || record?.lines?.length === 0) {
// 		return null;
// 	}

// 	return (
// 		<>
// 			<Typography variant="h6">{translate('Lines')}</Typography>
// 			<ReferenceArrayField record={record} addLabel={false} reference="lines" source="lines">
// 				<LineRow exclude={['site']} />
// 			</ReferenceArrayField>
// 		</>
// 	);
// };

const PackageList = ({ record }) => {
	const { permissions } = usePermissions();

	if (!permissions?.includes('can_view_packages') || !record?.packages?.length || record?.packages?.length === 0) {
		return null;
	}

	return (
		<ReferenceManyField addLabel={false} reference="packages" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
			<PackageServiceGridList />
		</ReferenceManyField>
	);
};

const PackageLineList = ({ record }) => {
	const { permissions } = usePermissions();

	if (!permissions?.includes('can_view_packages')) {
		return null;
	}

	return (
		<ReferenceManyField addLabel={false} reference="package_lines" target="site_id" pagination={<Pagination />} filter={{ archived: false }}>
			<PackageLineGrid />
		</ReferenceManyField>
	);
};

const Tabs = (props) => {
	const record = useRecordContext();
	const { permissions } = usePermissions();

	return (
		<TabbedShowLayout {...props}>
			<Tab label="summary">
				<Content />
				<DeviceList />
			</Tab>
			{(permissions?.includes('can_view_packages') && record?.packages?.length > 0) && (
				<Tab label="Packages">
					<PackageList />
				</Tab>
			)}
			{/* TODO: fix condition later to hide when no package line */}
			{(permissions?.includes('can_view_packages') && record?.packages?.length > 0) && (
				<Tab label="Package lines">
					<PackageLineList />
				</Tab>
			)}
		</TabbedShowLayout>
	);
};

const SiteShow = props => {
	const translate = useTranslate();
	const locale = useLocale();
	const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));

	const handleClickBar = (data) => {
		setStartDate(moment(data.date).startOf('month').format('YYYY-MM-DD'));
		setEndDate(moment(data.date).endOf('month').format('YYYY-MM-DD'));
	};

	return (
		<>
			<Show title={<SiteTitle />} actions={<SiteActions />} {...props}>
				<Tabs />
			</Show>
			<Show {...props} actions={null}>
				<SimpleShowLayout>
					<UsageGraph freq='monthly' title={translate("Data Usage monthly (GB)")} onClickBar={handleClickBar} />
					<UsageGraph
						freq='daily'
						title={translate("Data Usage") + ` ${new Date(startDate).toLocaleString(locale, { month: 'long', year: 'numeric' })}`}
						options={{ start_date: startDate, end_date: endDate }}
					/>
				</SimpleShowLayout>
			</Show>
		</>
	);
};

export default SiteShow;